<template>
  <section>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="6" class="mb-2">
          <h6>No / Tanggal SPPD</h6>
          <p class="card-text mb-25">
            {{ currentSppd.sppd_number || '-' }} /
            {{ currentSppd.letter_date || '-' | moment('DD MMMM YYYY') }}
          </p>
        </b-col>
        <b-col cols="12" md="6" class="mb-2">
          <h6>{{ employee.type === 'pegawai' ? 'NIPPOS' : 'NIK' }} / Nama</h6>
          <p class="card-text mb-25">
            {{ employee.type === 'pegawai' ? employee.nippos : employee.nik }}
            / {{ employee.type === 'pegawai' ? employee.nama : employee.name }}
          </p>
        </b-col>
        <b-col cols="12" md="6" class="mb-2">
          <h6>Jenis Fasilitas</h6>
          <p class="card-text mb-25">
            {{ sppdFacilityType }}
          </p>
        </b-col>
        <b-col cols="12" md="6" class="mb-2">
          <h6>Jabatan</h6>
          <p class="card-text mb-25">
            {{
              employee.type === 'pegawai'
                ? employee.descjabatan
                : employee.position
            }}
          </p>
        </b-col>
        <!-- v-if="sppd.submitted_date" -->
        <b-col cols="12" md="6" class="mb-2">
          <h6>Tanggal Diajukan</h6>
          <p class="card-text mb-25">
            {{ currentSppd.submission_date || '-' | moment('DD MMMM YYYY') }}
          </p>
        </b-col>
        <b-col cols="12" md="6" class="mb-2">
          <h6>Bagian/Unit Kerja</h6>
          <p class="card-text mb-25">
            {{ employee.namabagian || '-' }}
          </p>
        </b-col>
        <b-col cols="12" md="6" class="mb-2">
          <h6>Nomor Rekening</h6>
          <p class="card-text mb-25">{{ no_rek }}</p>
        </b-col>
        <b-col cols="12" md="6" class="mb-2">
          <h6>Status</h6>
          <p class="card-text mb-25">
            {{ currentSppd.status_paid_detail || '-' }}
          </p>
        </b-col>
      </b-row>
      <div>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="secondary"
          class="float-right mb-1"
          @click="print"
        >
          <span>Cetak</span>
        </b-button>
        <b-table
          striped
          hover
          responsive
          show-empty
          :items="items"
          :fields="fields"
        >
          <template #cell(no)="data">{{ data.index + 1 }}</template>

          <template #cell(type)="data">{{
            capitalizing(data.item.type)
          }}</template>

          <template #cell(total)="data">
            {{ data.item.total | currency }}
          </template>

          <template #cell(status)="data">
            <span
              v-if="
                data.item.status === null ||
                (data.item.type === 'uang harian' && !data.item.status)
              "
              >-</span
            >
            <span v-else-if="data.item.status == 1" class="text-success">
              Disetujui
            </span>
            <span v-else class="text-danger">Ditolak</span>
          </template>
        </b-table>
        <div class="mt-2">
          <h6>Jumlah yang Diajukan</h6>
          <p>{{ totalCost | currency }}</p>
        </div>
        <div class="mt-2">
          <h6>Mengetahui</h6>
          <p v-if="currentSppd.approval_payment_admin">
            {{ currentSppd.approval_payment_admin.employee || '-' }} /
            {{ currentSppd.approval_payment_admin.name || '-' }} /
            {{ currentSppd.approval_payment_admin.position || '-' }}
          </p>
          <p v-else>-</p>
        </div>
        <div class="mt-2">
          <h6>Yang Membayar</h6>
          <p v-if="currentSppd.approval_payment_superior">
            {{ currentSppd.approval_payment_superior.employee || '-' }} /
            {{ currentSppd.approval_payment_superior.name || '-' }} /
            {{ currentSppd.approval_payment_superior.position || '-' }}
          </p>
          <p v-else>-</p>
        </div>
        <div class="mt-2">
          <h6>Yang Menerima</h6>
          <p>
            {{
              employee.type === 'non-pegawai'
                ? `${employee.nik || '-'} / ${employee.name || '-'} / ${
                    employee.position || '-'
                  }`
                : `${employee.nippos || '-'} / ${employee.nama || '-'} / ${
                    employee.descjabatan || '-'
                  }`
            }}
          </p>
        </div>
      </div>
    </b-card-body>
    <b-card-footer align="right">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="reset"
        class="mr-1"
        variant="outline-secondary"
        :to="{ name: 'manajemen-sppd' }"
      >
        Kembali
      </b-button>
    </b-card-footer>
  </section>
</template>

<script>
/* eslint-disable eqeqeq */
import {
  BCardBody,
  BCardFooter,
  BRow,
  BCol,
  BButton,
  BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'

export default {
  components: {
    BCardBody,
    BCardFooter,
    BRow,
    BCol,
    BButton,
    BTable,
  },
  directives: {
    Ripple,
  },
  props: {
    sppd: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const sppdId = this.$route.params.id

    return {
      sppdId,
      currentSppd: this.sppd,
      config: {
        api: '/sppd',
        redirect: `/manajemen-sppd/${sppdId}/detail`,
      },
      fields: [
        'no',
        { key: 'type', label: 'Komponen Biaya' },
        { key: 'note', label: 'Rincian' },
        { key: 'total', label: 'Total Diajukan' },
        { key: 'status', label: 'Status' },
      ],
      items: [],
      totalCost: 0,
    }
  },
  computed: {
    sppdFacilityType() {
      if (this.currentSppd.sppd_facility_type_id == 1) {
        return 'Perjalanan Dinas Biasa'
      }
      if (this.currentSppd.sppd_facility_type_id == 2) {
        return 'Perjalanan Dinas Fasilitas Khusus'
      }
      return '-'
    },
    employee() {
      return this.currentSppd.employee?.raw
        ? JSON.parse(this.currentSppd.employee.raw)
        : {}
    },
    no_rek() {
      return this.currentSppd.employee?.no_rek || '-'
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      axios
        .get(`${this.config.api}/${this.$route.params.id}/payment`)
        .then(res => {
          const { data } = res.data
          this.currentSppd = data
          this.items = data.payments
          this.totalCost = data.total_payment
        })
        .catch(err => {
          console.error(err)
        })
    },
    capitalizing(text) {
      let words = text.toLowerCase().split(' ')
      words = words
        .map(word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
        .join(' ')
      return words
    },
    print() {
      axios
        .get(`payments/${this.currentSppd.id}/print`, {
          responseType: 'blob',
        })
        .then(res => {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: 'application/pdf' }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `Bukti-Pembayaran-${this.currentSppd.sppd_number}.pdf`,
          )
          document.body.appendChild(link)
          link.click()
          link.remove()
        })
    },
  },
}
</script>
