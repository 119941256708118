import { render, staticRenderFns } from "./DetailInfo.vue?vue&type=template&id=7203c20e&"
import script from "./DetailInfo.vue?vue&type=script&lang=js&"
export * from "./DetailInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports